.media-image {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  object-fit: contain;
}

.media-image--aspect-ratio-1-1 {
  aspect-ratio: 1/1;
}

.media-image--aspect-ratio-2-1 {
  aspect-ratio: 2/1;
}

.media-image--aspect-ratio-4-3 {
  aspect-ratio: 4/3;
}

.media-image--aspect-ratio-16-9 {
  aspect-ratio: 16/9;
}

.media-image--object-fit-cover {
  object-fit: cover;
}

.media-image--object-fit-fill {
  object-fit: fill;
}

.media-image--border {
  border: 2px solid var(--color-surface-outline);
  border-radius: var(--border-radius-m);
}
